import { Controller } from "@hotwired/stimulus";
// @ts-ignore
import Clappr from "clappr";
// @ts-ignore
import Video360 from "clappr-video360";

export default class extends Controller {
	declare player: any;

	connect() {
		// let height = "13rem";
		let height = "auto";
		// @ts-ignore
		let width = 320;

		document.body.classList.remove("w-full", "h-full");

		// @ts-ignore
		const source = this.element.dataset.src;
		// @ts-ignore
		const is360 = this.element.dataset.threeSixty === "true";
		let plugins = {};
		if (is360) {
			plugins = { container: [Video360] };
		}

		this.player = new Clappr.Player({
			playback: {
				preload: "metadata",
				disableContextMenu: false,
				controls: false,
				crossOrigin: "use-credentials",
				playInline: true,
				minimumDvrSize: null,
				externalTracks: [],
				hlsjsConfig: {},
				shakaConfiguration: {},
			},
			source,
			// width,
			// height,
			plugins,
		});

		this.player.attachTo(this.element);
		this.player.core.toggleFullscreen = () => {};
		this.player.getPlugin("click_to_pause").disable();
	}
}
